/* eslint-disable no-lonely-if */
import { MapMouseEvent, MapTouchEvent } from 'mapbox-gl';
import {
  daasVesselToMyFleetVessel,
  getVesselLocationsDAAS,
  mapResponseToHistoricVesselPoints,
} from '../../../api';
import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import {
  handleMultiSelect,
  handleSingleSelect,
} from '../../../maritime-menu-options/fleets-panel/manage-fleets/manage-fleet.utils';
import { MyFleetVessel } from '../../../maritime-menu-options/my-fleet-panel/myFleetVessels.model';
import { Vessel } from '../../../models/vessels/vessel.model';
import { addHistoryItem } from '../../../nav-history.slice';
import { setSelectedAlertId } from '../../../state/alerts/alerts.slice';
import store from '../../../store';
import TenantId from '../../../tenant';
import GeoHelper from '../../../utils/geo-helpers.utils';
import MapHelpers from '../../map.utils';
import { updateMyFleetVesselLocationInStore } from './panel-vessel-onClick';

async function onVesselClick(
  clickEvent: MapMouseEvent | MapTouchEvent,
  feature: mapboxgl.MapboxGeoJSONFeature
) {
  const { alertingVessels } = store.getState().alertingVessels;
  const { filters } = store.getState().alertingPanel;
  const { myFleet } = store.getState().myFleet;
  const { fleetsVessels } = store.getState().fleetsVessels;
  const { nearbyVessels } = store.getState().nearbyVessels;
  const { historicVessels } = store.getState().historicVessels;
  const { secondaryMenuOpen } = store.getState().menu;
  const { idToken } = store.getState().user;

  // can't use useAccessControl because we're not in a react component
  const canMultiSelect = idToken?.tenantId === TenantId.GEOLLECT;

  const clickedVessel = feature.properties as Vessel;

  // Is the vessel alerting?
  const filteredAlertingVessels = alertingVessels?.filter(
    (vessel) => !filters.hiddenAlerts.includes(vessel.alertType)
  );
  const alerting = filteredAlertingVessels?.find(
    (vessel) => vessel.vessel_id === clickedVessel.vessel_id
  );

  // Load the vessel from myfleet and fleets
  const myFleetVessel = myFleet?.byId[clickedVessel.vessel_id];
  const fleetsVessel = fleetsVessels?.byId[clickedVessel.vessel_id];
  const derivedVessel = myFleetVessel || fleetsVessel;

  // TODO code commented to fix bug for NGG-587. Part of multiple vessel selection feature but requires more work.
  // store.dispatch(setSelectedDossier(EDossiers.VESSEL));
  // Part of SUS-254 commented out to disable zooming if clicked on within map.
  if (GeoHelper.assertIsPointFeature(feature)) {
    const preventZoomOut = true;
    const zoomLevel = undefined;
    const isMultiSelected = clickEvent.originalEvent.ctrlKey && canMultiSelect;
    if (!isMultiSelected) {
      MapHelpers.zoomToPoint(
        feature.geometry.coordinates,
        zoomLevel,
        preventZoomOut
      );
    }
    // This bug fix is for NGG-587. requires more work and has been commented out to allow me to work on another feature.
    // const vessel = {
    //   latitude: feature.geometry.coordinates[1],
    //   longitude: feature.geometry.coordinates[0],
    //   ...feature.properties,
    // };
    // store.dispatch(setSelectedVessels([vessel as Vessel]));
  }

  if (derivedVessel) {
    // update the vessel location in the myfleet store so that the vessel is moved on the map when selected
    // (otherwise there may be a gap between the myfleet icon and the vessel history icon)

    updateMyFleetVesselLocationInStore(derivedVessel);
    const selectedVesselIds =
      store.getState().vesselDossier.selectedVessels?.allIds;
    const set = new Set(selectedVesselIds || []);
    if (!secondaryMenuOpen) {
      store.dispatch(addHistoryItem({ type: 'myFleet' }));
    }
    if (clickEvent.originalEvent.ctrlKey && canMultiSelect) {
      handleMultiSelect(derivedVessel as MyFleetVessel, set, store.dispatch);
    } else {
      handleSingleSelect(derivedVessel as MyFleetVessel, set, store.dispatch);
    }

    if (!alerting) {
      window.history.pushState(
        null,
        '',
        `/fleet?ids=${Array.from(set).join(',')}`
      );
    } else {
      store.dispatch(setSelectedAlertId(derivedVessel.vessel_id));

      window.history.pushState(
        null,
        '',
        `/notifications/${derivedVessel.vessel_id}`
      );
      // Keep the icon the same
    }
  } else if (nearbyVessels) {
    // This might be a nearby vessel
    const nearbyVesselDaas = nearbyVessels?.data?.[clickedVessel.vessel_id];

    if (nearbyVesselDaas) {
      const nearbyVessel = daasVesselToMyFleetVessel(nearbyVesselDaas.vessel!);

      const selectedVesselIds =
        store.getState().vesselDossier.selectedVessels?.allIds;
      const set = new Set(selectedVesselIds || []);
      store.dispatch(setSelectedDossier(EDossiers.VESSEL));
      if (clickEvent.originalEvent.ctrlKey && canMultiSelect) {
        handleMultiSelect(nearbyVessel as MyFleetVessel, set, store.dispatch);
      } else {
        handleSingleSelect(nearbyVessel as MyFleetVessel, set, store.dispatch);
      }
    }
  } else if (historicVessels) {
    const historicVesselDaas = historicVessels?.data?.[clickedVessel.vessel_id];
    if (historicVesselDaas) {
      // Need to first get the related vessel points as the lastPositionUpdate may not actually be the latest location.
      const locations = await getVesselLocationsDAAS(
        historicVesselDaas.vessel!.staticData.mmsi.toString(),
        new Date(),
        1,
        '1d'
      );
      const points = mapResponseToHistoricVesselPoints(locations);
      points.sort((a, b) => a.timestamp - b.timestamp);
      // Pass in the last location in the list (The most up-to-date location)
      const historicVessel = daasVesselToMyFleetVessel(
        historicVesselDaas.vessel!,
        points[points.length - 1]
      );

      const selectedVesselIds =
        store.getState().vesselDossier.selectedVessels?.allIds;
      const set = new Set(selectedVesselIds || []);
      store.dispatch(setSelectedDossier(EDossiers.VESSEL));
      if (clickEvent.originalEvent.ctrlKey && canMultiSelect) {
        handleMultiSelect(historicVessel as MyFleetVessel, set, store.dispatch);
      } else {
        handleSingleSelect(
          historicVessel as MyFleetVessel,
          set,
          store.dispatch
        );
      }
    }
  }
}

export default onVesselClick;

import { Amplify } from '@aws-amplify/core';
import { Storage } from '@aws-amplify/storage';
import '@aws-amplify/ui-react/styles.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import awsExports from './aws-exports';
import ErrorPage from './common-components/error-components/error-page/error-page';
import './index.scss';
import { StorageDirectory, StorageLevel } from './models/documents/document';
import AppThemeProvider from './mui-themes/app-theme-provider';
import reportWebVitals from './reportWebVitals';
import RequireAuth from './require-auth';
import store from './store';
import UserPreferencesPage from './user-settings/user-preferences/user-preferences-page';

// Buffer exists in browser context, but gets confused in 'npm start' transpilation
// eslint-disable-next-line global-require
window.Buffer = window.Buffer || require('buffer').Buffer;

Amplify.configure(awsExports);
Storage.configure({
  customPrefix: {
    private: StorageDirectory[StorageLevel.PRIVATE],
    public: '',
  },
});

// safe check to default to error page if environment isn't 'production'
const errorElement =
  process.env.REACT_APP_SHOW_CUSTOM_ERROR_PAGE === 'true' ? (
    <ErrorPage />
  ) : undefined;

const router = createBrowserRouter([
  {
    path: '/admin',
    lazy: async () => {
      const RequiresAdmin = (await import('./admin/requires-admin')).default;
      const AdminPage = (await import('./admin/admin-page/admin-page')).default;
      return {
        Component: () => (
          <RequireAuth>
            <RequiresAdmin fallback={<Navigate to="/" />}>
              <AdminPage />
            </RequiresAdmin>
          </RequireAuth>
        ),
      };
    },
    errorElement,
    children: [
      {
        path: 'companies',
        lazy: async () => {
          const CompaniesPage = (
            await import(
              './admin/admin-page/manage-companies/company-view/admin-view'
            )
          ).default;
          return {
            Component: CompaniesPage,
          };
        },
        errorElement,
      },
      {
        path: 'companies/:id',
        lazy: async () => {
          const CompanyPage = (
            await import(
              './admin/admin-page/manage-companies/company-view/company-view'
            )
          ).default;

          return {
            Component: CompanyPage,
          };
        },
        errorElement,
      },
      {
        path: 'companies/:id/vessel-lists/:id',
        lazy: async () => {
          const VesselListPage = (
            await import(
              './common-components/manage-vessel-lists/vessel-list-view/vessel-list-view'
            )
          ).default;
          return {
            Component: VesselListPage,
          };
        },
        errorElement,
      },
      {
        path: 'companies/:id/user/:id',
        lazy: async () => {
          const UserPage = (
            await import(
              './admin/admin-page/manage-companies/company-view/manage-users/user-view/user-view'
            )
          ).default;
          return {
            Component: UserPage,
          };
        },
        errorElement,
      },
    ],
  },
  {
    path: '/user-preferences',
    lazy: async () => ({
      Component: () => (
        <RequireAuth>
          <UserPreferencesPage />
        </RequireAuth>
      ),
    }),
    errorElement,
    children: [
      {
        path: 'preferences',
        lazy: async () => {
          const UserPrefPageView = (
            await import(
              './user-settings/user-preferences/user-preferences-page-view'
            )
          ).default;
          return {
            Component: UserPrefPageView,
          };
        },
        errorElement,
      },
      {
        path: 'vessel-lists/:id',
        lazy: async () => {
          const VesselListPage = (
            await import(
              './common-components/manage-vessel-lists/vessel-list-view/vessel-list-view'
            )
          ).default;
          return {
            Component: VesselListPage,
          };
        },
        errorElement,
      },
    ],
  },
  {
    path: '/*',
    lazy: async () => {
      const App = (await import('./App')).default;
      return {
        Component: () => (
          <RequireAuth>
            <App />
          </RequireAuth>
        ),
      };
    },
    errorElement,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppThemeProvider>
        <RouterProvider router={router} />
      </AppThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export enum FleetStatusMessages {
  LOADING_MESSAGE = 'Loading...',
  CREATING_FLEET_MESSAGE = 'Creating Fleet...',
  SUCCESS_CREATING_FLEET_MESSAGE = 'Fleet created successfully',
  ERROR_ADDING_VESSELS_MESSAGE = 'Error updating vessel details',
  ADDING_VESSELS_TO_FLEET_MESSAGE = 'Adding updated vessels to Fleet...',
  SUCCESS_ADDING_VESSELS_TO_FLEET_MESSAGE = 'Vessels added to Fleet successfully',
  ERROR_ADDING_VESSELS_TO_FLEET_MESSAGE = 'Error adding vessels to Fleet',
  RETRIEVING_VESSEL_DATA_MESSAGE = 'Retrieving current vessel data...',
  VESSELS_RETRIEVED_SUCCESS_MESSAGE = 'Vessels retrieved successfully',
  VERIFYING_FLEET_DATA_MESSAGE = 'Verifying Fleet ',
  VESSELS_ADDED_SUCCESS_MESSAGE = 'Vessels added to Fleet',
  ADDING_VESSELS_ERROR_MESSAGE = 'Error adding vessels to Fleet',
  PROCESSING_VESSELS_MESSAGE = 'Processing vessel lists...',
  NO_VESSELS_MATCHED_MESSAGE = 'No vessels matched the provided data',
  AMENDING_FLEET_MESSAGE = 'Processing amend Fleet actions...',
  SUCCESS_AMENDING_FLEET_MESSAGE = 'Fleet amended successfully',
  ERROR_EXPLAINING_AMEND_FLEET_MESSAGE = 'Error occurred while checking Fleet',
  ERROR_AMENDING_FLEET_MESSAGE = 'Error occurred while amending Fleet',
  ERROR_RETRIEVING_VESSEL_DATA_MESSAGE = 'Error occurred while retrieving vessel data',
  EXPLAINING_AMEND_ACTIONS_MESSAGE = 'Checking Fleet for required actions...',
  UPDATING_VESSEL_DETAILS_MESSAGE = 'Updating vessel details...',
  CREATING_FLEET_FROM_FILTERS_MESSAGE = 'Creating Fleet with currently applied Filters',
  ERROR_CREATING_FLEET_MESSAGE = 'Error creating Dynamic Fleet',
}

export default FleetStatusMessages;

import mapboxgl, { Layer } from 'mapbox-gl';
import MapLayer from './map-layer.enum';

import MuiPlaceIcon from '../../assets/icons/generic/mui-place.svg';
import GlobalAisCargoStatic from '../../assets/icons/global-ais/global-ais-cargo-static.svg';
import GlobalAisCargo from '../../assets/icons/global-ais/global-ais-cargo.svg';
import GlobalAisFishingStatic from '../../assets/icons/global-ais/global-ais-fishing-static.svg';
import GlobalAisFishing from '../../assets/icons/global-ais/global-ais-fishing.svg';
import GlobalAisHighSpeedCraftStatic from '../../assets/icons/global-ais/global-ais-high-speed-craft-static.svg';
import GlobalAisHighSpeedCraft from '../../assets/icons/global-ais/global-ais-high-speed-craft.svg';
import GlobalAisOtherStatic from '../../assets/icons/global-ais/global-ais-other-static.svg';
import GlobalAisOther from '../../assets/icons/global-ais/global-ais-other.svg';
import GlobalAisPassengerStatic from '../../assets/icons/global-ais/global-ais-passenger-static.svg';
import GlobalAisPassenger from '../../assets/icons/global-ais/global-ais-passenger.svg';
import GlobalAisPleasureCraftStatic from '../../assets/icons/global-ais/global-ais-pleasure-craft-static.svg';
import GlobalAisPleasureCraft from '../../assets/icons/global-ais/global-ais-pleasure-craft.svg';
import GlobalAisTankerStatic from '../../assets/icons/global-ais/global-ais-tanker-static.svg';
import GlobalAisTanker from '../../assets/icons/global-ais/global-ais-tanker.svg';
import GlobalAisTugStatic from '../../assets/icons/global-ais/global-ais-tug-static.svg';
import GlobalAisTug from '../../assets/icons/global-ais/global-ais-tug.svg';
import HighlightIncidentMarker from '../../assets/icons/incident/default-incident-icon-marker-alert.png';
import DefaultIncidentMarker from '../../assets/icons/incident/default-incident-icon-marker.png';
import PortRICruiseSelectedIcon from '../../assets/icons/ports/ri-port-cruise-selected.svg';
import PortRICruise from '../../assets/icons/ports/ri-port-cruise.svg';
import PortRIInland from '../../assets/icons/ports/ri-port-inland.svg';
import PortRISelectedIcon from '../../assets/icons/ports/ri-port-selected.svg';
import PortRIIcon from '../../assets/icons/ports/ri-port.svg';
import PortSelectedIcon from '../../assets/icons/ports/wpi-port-selected.svg';
import PortIcon from '../../assets/icons/ports/wpi-port.svg';
import Outline from '../../assets/icons/vessels/outline.png';
import PathIsoscelesTriangleRed from '../../assets/icons/vessels/path-triangle-isosceles-red.png';
import PathIsoscelesTriangle from '../../assets/icons/vessels/path-triangle-isosceles.png';
import CurrentVesselWithBorder from '../../assets/icons/vessels/vessel-border.svg';
import vesselSearchAreaAmber from '../../assets/icons/vessels/vessel-navigation-icon-orange.png';
import vesselSearchAreaRed from '../../assets/icons/vessels/vessel-navigation-icon-red.png';
import vesselSearchArea from '../../assets/icons/vessels/vessel-navigation-icon.png';
import CurrentVessel from '../../assets/icons/vessels/vessel-standard.svg';
import CustomMapEvents from '../custom-map-events.enum';
import MapHelpers from '../map.utils';
import addMapImage from './layer-utils/add-image-to-layer';
import MapLayerIcon from './map-layer-icon';

namespace MapLayerManager {
  interface IGroupLayerProps {
    groupLayers?: string[];
  }

  export function getLayerIdsFromSourceGroups(sourceGroups: string[]) {
    return (
      sourceGroups?.flatMap((group) =>
        MapLayerManager.findLayersByGroupLayerId(group).map(
          (layer: { id: any }) => layer.id
        )
      ) ?? ([] as string[])
    );
  }

  // remove layer data and layer view from map
  export const destroyLayer = (layer: string) => {
    MapHelpers.removeLayer(layer);
    MapHelpers.removeSource(layer);
  };

  export const groupLayerMetaData = (
    groupLayers: string[]
    // eslint-disable-next-line object-shorthand
  ): IGroupLayerProps => ({ groupLayers: groupLayers });

  export const findLayersByGroupLayerId = (groupLayer: string) => {
    const allLayers = MapHelpers.getAllLayers();

    const filteredLayers = allLayers.filter((layer: mapboxgl.Layer) => {
      const { metadata } = layer;
      return (
        metadata &&
        metadata.groupLayers &&
        metadata.groupLayers.includes(groupLayer)
      );
    });

    return filteredLayers;
  };

  export const AddLayerinGroups = (
    layer: mapboxgl.AnyLayer,
    groupLayers: string[]
  ) => {
    // eslint-disable-next-line no-param-reassign
    (layer as Layer).metadata = {
      ...(layer as Layer).metadata,
      ...MapLayerManager.groupLayerMetaData(
        [(layer as Layer).metadata?.groupLayers || [], ...groupLayers].flat()
      ),
    };

    MapHelpers.addLayer(layer);

    groupLayers.forEach((groupLayer) => {
      MapLayerManager.triggerLayerGroupChange(groupLayer);
    });
  };

  const LayerGroupChangeType = (layerId: string) =>
    `${layerId}_${CustomMapEvents.GROUP_LAYER_CHANGE}`;

  export const triggerLayerGroupChange = (groupLayerId: string) =>
    MapHelpers.fire(LayerGroupChangeType(groupLayerId));

  export const onLayerGroupChange = (
    groupLayerId: string,
    callback: (ev: any) => void
  ) => {
    MapHelpers.addMapEventListener(
      LayerGroupChangeType(groupLayerId),
      null,
      callback
    );
  };

  export const removeLayerGroupChange = (
    groupLayerId: string,
    callback: (ev: any) => void
  ) => {
    MapHelpers.removeMapEventListener(
      LayerGroupChangeType(groupLayerId),
      callback
    );
  };

  export const loadMapImages = () => {
    addMapImage({
      imageImport: CurrentVessel,
      imageName: MapLayerIcon.PLAIN_VESSEL.NORMAL,
      svg: { h: 60, w: 60 },
      convertToSDF: true,
    });
    addMapImage({
      imageImport: CurrentVessel,
      imageName: MapLayerIcon.PLAIN_VESSEL.ALERT,
      svg: { h: 60, w: 60 },
      convertToSDF: true,
    });
    addMapImage({
      imageImport: CurrentVessel,
      imageName: MapLayerIcon.PLAIN_VESSEL.NEARBY,
      svg: { h: 60, w: 60 },
      convertToSDF: true,
    });
    addMapImage({
      imageImport: CurrentVesselWithBorder,
      imageName: MapLayerIcon.PLAIN_VESSEL.BORDER,
      svg: { h: 60, w: 60 },
      convertToSDF: true,
    });
    addMapImage({
      imageImport: PathIsoscelesTriangle,
      imageName: MapLayerIcon.HISTORIC_POSITION,
    });
    addMapImage({
      imageImport: PathIsoscelesTriangleRed,
      imageName: MapLayerIcon.RADIO_POSITION,
    });
    addMapImage({
      imageImport: DefaultIncidentMarker,
      imageName: MapLayerIcon.INCIDENT.DEFAULT,
    });
    addMapImage({
      imageImport: HighlightIncidentMarker,
      imageName: MapLayerIcon.INCIDENT.SELECTED,
    });
    addMapImage({
      imageImport: Outline,
      imageName: MapLayer.VESSEL_FOCUS_RING,
    });
    addMapImage({
      imageImport: PortRIIcon,
      imageName: MapLayerIcon.PORT.DEFAULT_RI,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: PortRICruise,
      imageName: MapLayerIcon.PORT.CRUISE_RI,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: PortRIInland,
      imageName: MapLayerIcon.PORT.INLAND_RI,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: PortRISelectedIcon,
      imageName: MapLayerIcon.PORT.SELECTED_RI,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: PortRICruiseSelectedIcon,
      imageName: MapLayerIcon.PORT.SELECTED_CRUISE_RI,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: PortIcon,
      imageName: MapLayerIcon.PORT.DEFAULT,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: PortSelectedIcon,
      imageName: MapLayerIcon.PORT.SELECTED,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: MuiPlaceIcon,
      imageName: MapLayerIcon.WEATHER.PIN,
      svg: { h: 20, w: 14 },
      convertToSDF: true,
    });
    addMapImage({
      imageImport: vesselSearchArea,
      imageName: MapLayerIcon.VESSEL_SEARCH_AREA.NORMAL,
    });
    addMapImage({
      imageImport: vesselSearchAreaAmber,
      imageName: MapLayerIcon.VESSEL_SEARCH_AREA.AMBER,
    });
    addMapImage({
      imageImport: vesselSearchAreaRed,
      imageName: MapLayerIcon.VESSEL_SEARCH_AREA.ALERT,
    });
    addMapImage({
      imageImport: GlobalAisOther,
      imageName: MapLayerIcon.GLOBAL_AIS.DEFAULT,
      svg: { h: 60, w: 40 },
    });
    addMapImage({
      imageImport: GlobalAisOtherStatic,
      imageName: MapLayerIcon.GLOBAL_AIS.DEFAULT_STATIC,
      svg: { h: 30, w: 30 },
    });
    addMapImage({
      imageImport: GlobalAisCargo,
      imageName: MapLayerIcon.GLOBAL_AIS.CARGO,
      svg: { h: 60, w: 40 },
    });
    addMapImage({
      imageImport: GlobalAisCargoStatic,
      imageName: MapLayerIcon.GLOBAL_AIS.CARGO_STATIC,
      svg: { h: 30, w: 30 },
    });
    addMapImage({
      imageImport: GlobalAisFishing,
      imageName: MapLayerIcon.GLOBAL_AIS.FISHING,
      svg: { h: 60, w: 40 },
    });
    addMapImage({
      imageImport: GlobalAisFishingStatic,
      imageName: MapLayerIcon.GLOBAL_AIS.FISHING_STATIC,
      svg: { h: 30, w: 30 },
    });
    addMapImage({
      imageImport: GlobalAisHighSpeedCraft,
      imageName: MapLayerIcon.GLOBAL_AIS.HIGH_SPEED_CRAFT,
      svg: { h: 60, w: 40 },
    });
    addMapImage({
      imageImport: GlobalAisHighSpeedCraftStatic,
      imageName: MapLayerIcon.GLOBAL_AIS.HIGH_SPEED_CRAFT_STATIC,
      svg: { h: 30, w: 30 },
    });
    addMapImage({
      imageImport: GlobalAisPassenger,
      imageName: MapLayerIcon.GLOBAL_AIS.PASSENGER,
      svg: { h: 60, w: 40 },
    });
    addMapImage({
      imageImport: GlobalAisPassengerStatic,
      imageName: MapLayerIcon.GLOBAL_AIS.PASSENGER_STATIC,
      svg: { h: 30, w: 30 },
    });
    addMapImage({
      imageImport: GlobalAisPleasureCraft,
      imageName: MapLayerIcon.GLOBAL_AIS.PLEASURE_CRAFT,
      svg: { h: 60, w: 40 },
    });
    addMapImage({
      imageImport: GlobalAisPleasureCraftStatic,
      imageName: MapLayerIcon.GLOBAL_AIS.PLEASURE_CRAFT_STATIC,
      svg: { h: 30, w: 30 },
    });
    addMapImage({
      imageImport: GlobalAisTanker,
      imageName: MapLayerIcon.GLOBAL_AIS.TANKER,
      svg: { h: 60, w: 40 },
    });
    addMapImage({
      imageImport: GlobalAisTankerStatic,
      imageName: MapLayerIcon.GLOBAL_AIS.TANKER_STATIC,
      svg: { h: 30, w: 30 },
    });
    addMapImage({
      imageImport: GlobalAisTug,
      imageName: MapLayerIcon.GLOBAL_AIS.TUGS_AND_SPECIAL_CRAFT,
      svg: { h: 60, w: 40 },
    });
    addMapImage({
      imageImport: GlobalAisTugStatic,
      imageName: MapLayerIcon.GLOBAL_AIS.TUGS_AND_SPECIAL_CRAFT_STATIC,
      svg: { h: 30, w: 30 },
    });
  };
}

export default MapLayerManager;

import { FeatureCollection, Point } from '@turf/helpers';
import * as dateFns from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Position } from 'geojson';
import {
  AlertDataResponse,
  AlertingVessels,
  ParsedAlertDataResponse,
  ROIType,
} from '../maritime-menu-options/alert-panel/alertingVessels.model';
import {
  ExactMatchingVessel,
  FleetsFilters,
  FleetsListVessel,
  FleetsVessel,
  FleetsVesselFilter,
} from '../maritime-menu-options/fleets-panel/fleets.model';
import { setManageFleetVesselsLoadingMessage } from '../maritime-menu-options/fleets-panel/manage-fleets/manage-fleets.slice';
import { HistoricVesselPoint } from '../maritime-menu-options/history-panel/historic-vessel-point.model';
import { HistoryFormValues } from '../maritime-menu-options/history-panel/history-form/history-form-validators';
import { VesselSourceFilter } from '../maritime-menu-options/my-fleet-panel/myFleet-panel.slice';
import { MyFleetVessel } from '../maritime-menu-options/my-fleet-panel/myFleetVessels.model';
import {
  AlertTypesByTenant,
  DEFAULT_ALERTS,
  EAlertTypes,
} from '../models/alerts/alert-configuration';
import { RfEvent, RfTarget } from '../models/rf-data/rf-data.model';
import { DaasShipType } from '../models/vessels/all-ship-types';
import {
  GetVesselResponse,
  MaritimeAisApiLocation,
  MaritimeAisApiLocationData,
  MaritimeAisApiLocationDefined,
  MaritimeAisApiLocationOnlyResponse,
  MaritimeAisApiStaticResponse,
  MessageData,
  MessageDataWithPosition,
  VesselData,
} from '../models/vessels/maritime-ais-api';
import {
  Vessel,
  VesselFormItem,
  VesselSource,
} from '../models/vessels/vessel.model';
import { setAlertingVessels } from '../state/alerts/alerts.slice';
import { setError } from '../state/vessels/vessels.slice';
import store from '../store';
import TenantId from '../tenant';
import {
  DefaultHistoryDuration,
  DefaultHistoryFrequency,
} from '../user-settings/user-preferences/user-preferences.slice';
import { Token } from '../user/user.slice';
import { wrapRequest } from './base';

dayjs.extend(utc);

export const WEBSOCKET_CHUNK_SIZE = 200;

export interface GeoniusVesselData {
  vessel_id: string;
  vessel_name: string;
  imo: string;
  current_mmsi: string;
  vessel_type: string;
  company_id: string;
}
export interface RiskIntelligenceVesselData {
  shiptype: any;
  callsign: any;
  name: string;
  time: string;
  course: number;
  speed: number;
  imo: number;
  position: {
    latitude: number;
    longitude: number;
  };
}

export const getVesselsForCompany = async (
  companyId: string
): Promise<GeoniusVesselData[]> =>
  wrapRequest('get', 'geonius', `/vessels`, {
    queryStringParameters: {
      company_id: companyId,
      limit: 1000,
    },
  });

export const getRiskIntelligenceVessels = async (
  company: string
): Promise<RiskIntelligenceVesselData[]> =>
  wrapRequest('get', 'geonius', '/risk-intelligence/vessels', {
    queryStringParameters: {
      company,
    },
  });

export const getAlertingVesselsForCompany = async (): Promise<
  AlertDataResponse[]
> => wrapRequest('get', 'geonius', `/alerting-vessels`);

export const getLastKnownVesselLocations = async (
  mmsis: number[],
  limit: number = 1000,
  offset: number = 0,
  imos?: number[]
): Promise<MaritimeAisApiLocationData> =>
  wrapRequest('post', 'geonius', `/ais-data`, {
    body: {
      endpointSelection: 'location-messages',
      mmsis,
      mergeOnVessels: true,
      limit,
      offset,
      mostRecent: '',
      imos,
    },
  }).then(async (response) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { data_size } = response.metadata.pagination;

    if (data_size !== limit) {
      return response;
    }

    const nextResponse = await getLastKnownVesselLocations(
      mmsis,
      limit,
      offset + limit,
      imos
    );
    response.data = { ...response.data, ...nextResponse.data };

    return response;
  });

// historic-area-search 'mostRecent' is false by default and takes start and end dates as parameters
// nearby-vessels 'mostRecent' is true, and takes hours as a parameter
export const initialiseVesselsByLocationWebsocket = async (
  geometry: Position[][],
  clientId: string,
  mostRecent: boolean,
  hours?: number,
  startDate?: Date,
  endDate?: Date
): Promise<void> => {
  if (!hours && (!startDate || !endDate)) {
    throw new Error(
      "Either 'hours' or both 'startDate' and 'endDate' must be provided"
    );
  }

  let startDatetime: string | undefined;
  let endDatetime: string | undefined;

  if (hours) {
    endDatetime = new Date().toISOString();
    startDatetime = dateFns.subHours(new Date(), hours).toISOString();
  } else if (startDate && endDate) {
    startDatetime = startDate.toISOString();
    endDatetime = endDate.toISOString();
  }

  wrapRequest('post', 'geonius', `/ais-data`, {
    body: {
      endpointSelection: 'websocket/location-messages',
      targetArea: geometry,
      startDatetime,
      endDatetime,
      clientId,
      mostRecent,
    },
  });
};

export const getFleetWithLocationsForCompany = async (
  companyId: string
): Promise<MyFleetVessel[]> => {
  const companyVessels = await getVesselsForCompany(companyId);
  const mmsis = companyVessels.map((vessel) =>
    parseInt(vessel.current_mmsi, 10)
  );
  const response = await getLastKnownVesselLocations(mmsis);
  const { data } = response;
  const fleet = (
    Object.values(data).filter(
      (location) => location.vessel !== null
    ) as MaritimeAisApiLocationDefined[]
  )
    .map((value) => {
      // sometimes a location message has null position, so we need to find the first position that is not null
      const [long, lat] = value.messages.find((message) => message.position)
        ?.position?.coordinates ||
        value.vessel.lastPositionUpdate.position.coordinates || [null, null];
      if (!long || !lat) {
        // Vessel has no valid location messages
        return null;
      }
      return {
        name: value.vessel.staticData.name,
        imo: value.vessel.staticData.imo,
        mmsi: value.messages[0].mmsi,
        flag: value.messages[0].flag,
        heading: value.messages[0].heading,
        latitude: lat,
        longitude: long,
        course: value.messages[0].course,
        timestamp: value.messages[0].timestamp,
        vessel_id: value.vessel.vesselId,
        speed: value.messages[0].speed,
        callsign: value.vessel.staticData.callsign,
        shiptype: value.vessel.staticData.shipType,
        source: VesselSource.AIS, // Everything from cold is an AIS vessel
      };
    })
    .filter((vessel) => vessel !== null) as MyFleetVessel[];
  return fleet;
};

export const getVesselsWithLocationsForFleet = async (
  vessels: FleetsListVessel[],
  vesselListId: string
): Promise<FleetsVessel[]> => {
  const mmsis = vessels.map((vessel) => parseInt(vessel.current_mmsi, 10));
  const response = await getLastKnownVesselLocations(mmsis);
  const { data } = response;
  const unpackedData = Object.values(data);
  const fleet = vessels.map((vessel) => {
    const matchingVessel = unpackedData.find(
      (item) =>
        String(item.vessel?.staticData.mmsi) === vessel.current_mmsi &&
        String(item.vessel?.staticData.imo) === vessel.imo
    );

    return {
      mmsi: vessel.current_mmsi,
      imo: vessel.imo,
      vessel_id: vessel.vessel_id,
      vessel_list_id: [vesselListId],
      name: vessel.vessel_name,
      shiptype: vessel.vessel_type,
      flag: matchingVessel?.messages[0].flag,
      heading: matchingVessel?.messages[0].heading,
      latitude: matchingVessel?.messages[0]?.position?.coordinates[1],
      longitude: matchingVessel?.messages[0]?.position?.coordinates[0],
      course: matchingVessel?.messages[0].course,
      timestamp: matchingVessel?.messages[0].timestamp,
      speed: matchingVessel?.messages[0].speed,
      callsign: matchingVessel?.vessel?.staticData.callsign,
      source: VesselSource.AIS, // Everything from AIS DB is an AIS vessel
    };
  });
  return fleet;
};

export const matchVesselBySearch = (
  vessel: Vessel,
  searchTerm: string
): boolean =>
  vessel.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
  vessel.imo?.toString().includes(searchTerm.toLocaleString()) ||
  vessel.mmsi?.toString().includes(searchTerm.toLocaleString()) ||
  false;

export const applyVesselsFilter = (
  vessels: MyFleetVessel[],
  filters: FleetsFilters
): MyFleetVessel[] =>
  vessels?.filter((vessel) => {
    const matchByText = () => {
      const nameMatch =
        vessel.name &&
        vessel.name
          .toLocaleLowerCase()
          .includes(filters.search.toLocaleLowerCase());

      const flagMatch =
        vessel.flag &&
        vessel.flag
          .toLocaleLowerCase()
          .includes(filters.search.toLocaleLowerCase());

      const imoMatch =
        vessel.imo &&
        vessel.imo.toString().includes(filters.search.toLocaleString());

      const mmsiMatch =
        vessel.mmsi &&
        vessel.mmsi.toString().includes(filters.search.toLocaleString());

      return nameMatch || flagMatch || imoMatch || mmsiMatch;
    };

    const matchBySourceFilter = (
      v: MyFleetVessel,
      filter: VesselSourceFilter
    ) =>
      (filter.ais && v.source === VesselSource.AIS) ||
      (filter.riskIntelligence && v.source === VesselSource.RISK_INTELLIGENCE);

    const matchByVesselListFilter = (
      v: MyFleetVessel,
      vesselListsVessels: FleetsVesselFilter[]
    ) =>
      vesselListsVessels.some((vesselListVessel) => {
        if (
          vesselListVessel.name === v.name &&
          vesselListVessel.imo === `${v.imo}` &&
          vesselListVessel.mmsi === `${v.mmsi}`
        ) {
          return true;
        }
        return false;
      });

    const anySourceFilterSelected = () =>
      filters.vesselSource.ais || filters.vesselSource.riskIntelligence;

    if (anySourceFilterSelected()) {
      return matchByText() && matchBySourceFilter(vessel, filters.vesselSource);
    }

    const vesselListFilterSelected = () =>
      filters.vesselListsVessels.length &&
      filters.vesselListsVessels.length > 0;

    if (vesselListFilterSelected()) {
      return (
        matchByText() &&
        matchByVesselListFilter(vessel, filters.vesselListsVessels)
      );
    }

    return matchByText();
  });

export const getRiskIntelligenceFleetForCompany = async (
  companyId: string
): Promise<MyFleetVessel[]> => {
  const riVessels = await getRiskIntelligenceVessels(companyId);
  const riFleet = riVessels.map((vessel) => ({
    name: vessel.name,
    imo: vessel.imo,
    course: vessel.course,
    speed: vessel.speed,
    callsign: vessel.callsign,
    shiptype: vessel.shiptype,
    latitude: vessel.position.latitude,
    longitude: vessel.position.longitude,
    timestamp: vessel.time,
    vessel_id: `${vessel.imo}`,
    source: VesselSource.RISK_INTELLIGENCE,
  }));

  return riFleet;
};

interface StaticDataPoint {
  vesselId: string;
  mmsi: number;
  imo: number;
  draught: number;
  timestamp: number;
}

export function responseToStaticArray(
  data: MaritimeAisApiStaticResponse
): StaticDataPoint[] {
  const flatArray: StaticDataPoint[] = [];

  Object.values(data.data).forEach((vesselData) => {
    vesselData.messages.forEach((message) => {
      const staticDataPoint: StaticDataPoint = {
        vesselId: message.vesselId,
        mmsi: message.mmsi,
        imo: message.imo,
        draught: message.draught,
        timestamp: new Date(message.timestamp).getTime(),
      };
      flatArray.push(staticDataPoint);
    });
  });
  flatArray.sort((a, b) => a.timestamp - b.timestamp);
  return flatArray;
}

export const getStaticData = async (
  startDatetime: Date,
  endDatetime: Date,
  mmsis?: string[],
  imos?: string[],
  downsampleRate: string = '1m',
  limit: number = 10000,
  offset: number = 0
): Promise<MaritimeAisApiStaticResponse> =>
  wrapRequest('post', 'geonius', `/ais-data`, {
    body: {
      endpointSelection: 'static-messages',
      mmsis: mmsis?.map((mmsi) => parseInt(mmsi, 10)),
      imos: imos?.map((imo) => parseInt(imo, 10)),
      mergeOnVessels: true,
      limit,
      offset,
      // modify datetimes for database query
      startDatetime: dateFns.startOfDay(startDatetime).toISOString(),
      endDatetime: dateFns.endOfDay(endDatetime).toISOString(),
      downsampleRate,
    },
  }).then(async (response) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { data_size } = response.metadata.pagination;
    if (data_size === limit) {
      const nextMessages = await getStaticData(
        startDatetime,
        endDatetime,
        mmsis,
        imos,
        downsampleRate,
        limit,
        offset + limit
      );
      // for every vessel in next messages check if it already exists in messages, if not add it
      // if it does then append the messages to the existing vessel
      Object.entries(response.data).forEach(([vesselId]) => {
        const nextVesselData = nextMessages.data[vesselId];
        if (nextVesselData) {
          response.data[vesselId].messages.push(...nextVesselData.messages);
        } else {
          response.data[vesselId] = nextVesselData;
        }
      });
    }
    return response;
  });

// returns a dict of vessel messages, keyed by vesselId. Only vessels with messages are included
export const mergeMessagesOntoVessels = (
  vessels: Record<string, VesselData>,
  messages: MessageData[],
  reverseMessages: boolean = true
) => {
  const vesselsCopy: Record<string, MaritimeAisApiLocation> = {};
  messages.forEach((message) => {
    if (!message.vesselId) {
      return;
    }
    const vessel = vessels[message.vesselId];
    if (!vessel) {
      return;
    }
    if (!vesselsCopy[message.vesselId]) {
      vesselsCopy[message.vesselId] = {
        vessel,
        messages: [message],
      };
    } else {
      vesselsCopy[message.vesselId].messages.push(message);
    }
  });
  if (reverseMessages) {
    Object.values(vesselsCopy).forEach((vesselData) => {
      vesselData.messages.reverse();
    });
  }

  return vesselsCopy;
};

/**
 * The new API endpoint provides more+better info, but RI is not allowed to use it, so it's easier to convert the new data to the old shape (for now)
 * This function converts a single vessel+messages from the new API to the old shape
 * @param vesselData
 * @returns
 */
export const geollectDaasFormatToGeoniusColdFormat = ({
  messages,
  vessel,
}: {
  messages: MessageData[];
  vessel: VesselData;
}): HistoricVesselData => {
  const { vesselId } = vessel;

  const vesselMessagesArray = (
    messages.filter((message) => message.position) as MessageDataWithPosition[]
  ).map((message) => {
    const historicVesselPoint: HistoricVesselPoint = {
      id: message.messageId,
      vessel_id: vesselId,
      timestamp: new Date(message.timestamp).getTime(),
      created_at: new Date(message.createdAt).getTime(),
      speed: message.speed,
      rot: message.rot,
      collection_type: message.collectionType,
      maneuver: message.maneuver,
      name: vessel.staticData.name,
      imo: vessel.staticData.imo,
      mmsi: vessel.staticData.mmsi,
      callsign: vessel.staticData.callsign,
      shiptype: vessel.staticData.shipType,
      heading: message.heading,
      latitude: message.position.coordinates[1],
      longitude: message.position.coordinates[0],
      course: message.course,
      source: VesselSource.AIS,
    };
    return historicVesselPoint;
  });

  const lastItem = vesselMessagesArray[vesselMessagesArray.length - 1];

  if (!lastItem) {
    return {
      messages: [],
      vessel: {} as Vessel,
    };
  }

  return {
    messages: vesselMessagesArray,
    vessel: {
      name: vessel.staticData.name,
      vessel_id: vessel.vesselId,
      latitude: vessel.lastPositionUpdate.position.coordinates[1],
      longitude: vessel.lastPositionUpdate.position.coordinates[0],
      course: lastItem.course,
      speed: lastItem.speed,
      timestamp: lastItem.timestamp,
      heading: lastItem.heading,
      imo: vessel.staticData.imo,
      mmsi: vessel.staticData.mmsi,
      callsign: vessel.staticData.callsign,
      shiptype: vessel.staticData.shipType,
      source: VesselSource.AIS,
    },
  };
};

export const mapResponseToHistoricVesselPoints = (
  data: (MessageData & { position: Exclude<MessageData['position'], null> })[]
): HistoricVesselPoint[] => {
  if (!data) {
    throw new Error('The Vessel locations response is null or undefined.');
  }

  return data.reduce<HistoricVesselPoint[]>((acc, value) => {
    try {
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _id,
        timestamp,
        vesselId,
        mmsi,
        position,
        createdAt,
        speed,
        course,
        heading,
        collectionType,
        rot,
        maneuver,
      } = value;

      // Check if required fields are missing or invalid
      if (
        !_id ||
        !timestamp ||
        !vesselId ||
        !mmsi ||
        !position.coordinates[0] ||
        !position.coordinates[1] ||
        !createdAt
      ) {
        throw new Error(
          'Encountered null or undefined value in Vessel locations response data.'
        );
      }

      acc.push({
        id: _id,
        name: 'n/a',
        timestamp: new Date(timestamp).getTime(),
        vessel_id: vesselId,
        mmsi,
        latitude: position.coordinates[1],
        longitude: position.coordinates[0],
        created_at: new Date(createdAt).getTime(),
        speed,
        callsign: value.callsign,
        shiptype: value.shiptype,
        course,
        heading,
        collection_type: collectionType,
        rot,
        maneuver,
        source: VesselSource.AIS,
      });
    } catch (error) {
      //
    }
    return acc;
  }, []);
};

export const mapMaritimeAisApiLocationDataToHistoricVesselPoints = (
  data: MaritimeAisApiLocationData
): HistoricVesselPoint[] => {
  if (!data || !data.data) {
    throw new Error('The Maritime AIS API location data is null or undefined.');
  }

  const vesselDataArray = Object.values(data.data).filter(
    (value) => value.vessel
  ) as MaritimeAisApiLocationDefined[];

  return vesselDataArray.reduce<HistoricVesselPoint[]>((acc, vesselData) => {
    const { vessel, messages } = vesselData as {
      vessel: VesselData;
      messages: MessageData[];
    };

    messages.forEach((message) => {
      try {
        const {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _id,
          timestamp,
          vesselId,
          mmsi,
          position,
          createdAt,
          speed,
          course,
          heading,
          collectionType,
          rot,
          maneuver,
          flag,
        } = message;

        // Check if required fields are missing or invalid
        if (
          !_id ||
          !timestamp ||
          !vesselId ||
          !mmsi ||
          !createdAt ||
          position?.coordinates[0] === undefined ||
          position?.coordinates[1] === undefined
        ) {
          throw new Error(
            'Encountered null or undefined value in Vessel locations response data.'
          );
        }

        acc.push({
          id: _id,
          name: vessel.staticData.name || 'unknown',
          timestamp: new Date(timestamp).getTime(),
          vessel_id: vesselId,
          mmsi,
          latitude: position?.coordinates[1],
          longitude: position?.coordinates[0],
          created_at: new Date(createdAt).getTime(),
          speed,
          callsign: vessel.staticData.callsign,
          shiptype: vessel.staticData.shipType || 'unknown',
          course,
          heading,
          collection_type: collectionType,
          rot,
          maneuver,
          source: VesselSource.AIS,
          flag,
        });
      } catch (error) {
        throw new Error("Couldn't map vessel data to historic vessel points.");
      }
    });

    return acc;
  }, []);
};

export function responseToLocationArray(
  data: MaritimeAisApiLocationData,
  mergeToSingleArray: boolean = false
): HistoricVesselPoint[][] {
  // array of arrays to support the history of multiple vessels
  const resultsArray: HistoricVesselPoint[][] = [];
  if (mergeToSingleArray) {
    resultsArray.push([]);
  }

  (
    Object.values(data.data).filter(
      (value) => value.vessel
    ) as MaritimeAisApiLocationDefined[]
  ).forEach((vesselData) => {
    const definedVessel = vesselData as {
      vessel: VesselData;
      messages: MessageData[];
    };
    const converted = geollectDaasFormatToGeoniusColdFormat(definedVessel);
    if (mergeToSingleArray) {
      resultsArray[0].push(...converted.messages);
    } else {
      resultsArray.push(converted.messages);
    }
  });
  if (mergeToSingleArray) {
    // if merged to a single array, sort all results by timestamp
    resultsArray[0].sort((a, b) => a.timestamp - b.timestamp);
  }
  return resultsArray;
}

export const getVesselDAAS = async (
  mmsi: string
): Promise<VesselData | null> => {
  const result = await wrapRequest<GetVesselResponse>(
    'post',
    'geonius',
    `/ais-data`,
    {
      body: {
        endpointSelection: 'vessels',
        mmsis: [parseInt(mmsi, 10)],
        limit: 1,
        offset: 0,
      },
    }
  );
  if (result && result.data && result.data.length > 0) {
    return result.data[0];
  }
  return null;
};

interface GetVesselsDAASRequest {
  mmsis?: string[];
  imos?: string[];
  exact?: ExactMatchingVessel[];
  vesselIds?: string[];
  filters?: string[];
  limit?: number;
  offset?: number;
  startDatetime?: string;
  shipTypes?: string[];
  flags?: string[];
}

export const combineVesselsAndLocations = (
  vessels: VesselData[],
  locations: MaritimeAisApiLocationData,
  vessel_list_id: string
): FleetsVessel[] => {
  const vesselsWithLocations: FleetsVessel[] = vessels.map((vessel) => {
    const locationData = locations.data[vessel.vesselId];
    const latestMessage = locationData?.messages[0];
    return {
      mmsi: vessel.staticData.mmsi.toString(),
      imo: String(vessel.staticData.imo),
      vessel_id: vessel.vesselId,
      vessel_list_id: [vessel_list_id],
      name: vessel.staticData.name,
      shiptype: vessel.staticData.shipType,
      flag: latestMessage?.flag,
      heading: latestMessage?.heading,
      latitude: latestMessage?.position?.coordinates[1],
      longitude: latestMessage?.position?.coordinates[0],
      course: latestMessage?.course,
      timestamp: latestMessage?.timestamp,
      speed: latestMessage?.speed,
      callsign: vessel.staticData.callsign,
      source: VesselSource.AIS,
    };
  });
  return vesselsWithLocations;
};

export const dynamicVesselsTwoWeeks = dateFns
  .subWeeks(new Date(), 2)
  .toISOString()
  .split('.')[0];

interface GetDynamicVesselsDAASParams {
  dataCallback: (
    data: FleetsVessel[],
    fleetsData: FleetsListVessel[],
    loading: boolean
  ) => void;
  limit?: number;
  offset?: number;
  flags?: string[] | null;
  shipTypes?: DaasShipType[] | null;
  vessel_list_id: string;
}
/**
 * Very simmilar to getVesselsDAAS, the difference being you can pass in shipTypes and flags to filter through.
 * We also expect this to take a long time to complete, so we have a callback function to handle the data.
 * @param {number} limit - The maximum number of vessels to return.
 * @param {number} offset - Used with pagination to start from a different point.
 * @param {string[]} flags - The ISO 3166 country code to look for.
 * @param {DaasShipType[]} shipTypes - The type of vessels to look for, e.g GENERAL_CARGO (See ship-types.ts).
 * @param {function} dataCallback - A callback function to handle the data and add it to the store.
 * @param {string} vessel_list_id - The id of the vessel list to add the vessels to.
 *
 * @returns {Promise<void>}
 */
export const getDynamicVesselsDAASWithLocation = async ({
  dataCallback,
  limit = 1000,
  offset = 0,
  flags,
  shipTypes,
  vessel_list_id,
}: GetDynamicVesselsDAASParams): Promise<void> => {
  const response = await wrapRequest<GetVesselResponse>(
    'post',
    'geonius',
    `/ais-data`,
    {
      body: {
        endpointSelection: 'vessels',
        shipTypes: shipTypes ?? [],
        flags: flags ?? [],
        offset,
        limit,
        lastUpdatedSince: dynamicVesselsTwoWeeks,
      },
    }
  );

  const vesselsData: VesselData[] = [...response.data];
  const mmsis: number[] = [];
  const imos: number[] = [];
  vesselsData.forEach((vessel) => {
    mmsis.push(vessel.staticData.mmsi);
    if (vessel.staticData.imo !== null) {
      imos.push(vessel.staticData.imo);
    }
  });

  const locationData = await getLastKnownVesselLocations(
    mmsis,
    undefined,
    undefined,
    imos
  );
  const vessels = combineVesselsAndLocations(
    vesselsData,
    locationData,
    vessel_list_id
  );
  const fleetsData = vesselsData.map((vessel) => ({
    current_mmsi: vessel.staticData.mmsi.toString(),
    imo: vessel.staticData.imo ? vessel.staticData.imo.toString() : '',
    vessel_id: vessel.vesselId,
    vessel_name: vessel.staticData.name,
    vessel_type: vessel.staticData.shipType,
    vessel_list_id: [vessel_list_id],
  }));

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { data_size } = response.metadata.pagination;
  const morePagesToLoad = data_size === limit;
  dataCallback(vessels, fleetsData, morePagesToLoad);

  if (morePagesToLoad) {
    const newOffset = offset + limit;
    await getDynamicVesselsDAASWithLocation({
      dataCallback,
      limit,
      offset: newOffset,
      flags,
      shipTypes,
      vessel_list_id,
    });
  }
};

/**
 * Returns a list of vessels that match the passed in filters and flags.
 *
 * Very simmilar to getVesselsDAAS, the difference being you can pass in shipTypes and flags to filter through.
 * @param {number} limit - The maximum number of vessels to return.
 * @param {number} offset - Used with pagination to start from a different point.
 * @param {string[]} flags - The ISO 3166 country code to look for.
 * @param {string[]} shipTypes - The type of vessels to look for, e.g GENERAL_CARGO (See ship-types.ts).
 *
 * @returns {VesselData[]} vessels - Array of vessel information.
 */
export const getDynamicVesselsDAAS = async ({
  limit = 1000,
  offset = 0,
  flags = [],
  shipTypes = [],
}: GetVesselsDAASRequest): Promise<VesselData[]> => {
  const vessels: VesselData[] = [];

  const response = await wrapRequest<GetVesselResponse>(
    'post',
    'geonius',
    `/ais-data`,
    {
      body: {
        endpointSelection: 'vessels',
        shipTypes: shipTypes ?? [], // If undefined or empty use empty array instead
        flags: flags ?? [], // If undefined or empty use empty array instead
        offset,
      },
    }
  );

  vessels.push(...response.data);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { data_size } = response.metadata.pagination;
  if (data_size === limit) {
    // recurse until all vessels have been retrieved
    const newOffset = offset + limit;
    store.dispatch(
      setManageFleetVesselsLoadingMessage(`${newOffset} vessels processed...`)
    );
    // Recursive call to the same method for pagination.
    const nextVessels = await getDynamicVesselsDAAS({
      limit,
      offset: newOffset,
      shipTypes,
      flags,
    });
    vessels.push(...nextVessels);
  }
  return vessels;
};

export const getVesselsDAAS = async ({
  mmsis = [],
  imos = [],
  exact = [],
  startDatetime,
  vesselIds = [],
  limit = 1000,
  offset = 0,
}: GetVesselsDAASRequest): Promise<VesselData[]> => {
  const vessels: VesselData[] = [];
  const response = await wrapRequest<GetVesselResponse>(
    'post',
    'geonius',
    `/ais-data`,
    {
      body: {
        endpointSelection: 'vessels',
        mmsis:
          mmsis && mmsis.length > 0
            ? mmsis.map((mmsi) => parseInt(mmsi, 10))
            : undefined,
        imos:
          imos && imos.length > 0
            ? imos.map((imo) => parseInt(imo, 10))
            : undefined,
        exact: exact && exact.length > 0 ? exact : undefined,
        vesselIds:
          vesselIds && vesselIds.length > 0
            ? vesselIds.map((vesselId) => String(vesselId))
            : undefined,
        offset,
        lastUpdatedSince: startDatetime || undefined,
      },
    }
  );

  vessels.push(...response.data);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { data_size } = response.metadata.pagination;
  if (data_size === limit) {
    // recurse until all vessels have been retrieved
    const newOffset = offset + limit;
    store.dispatch(
      setManageFleetVesselsLoadingMessage(`${newOffset} vessels processed...`)
    );
    const nextVessels = await getVesselsDAAS({
      mmsis,
      imos,
      exact,
      vesselIds,
      limit,
      offset: newOffset,
    });
    vessels.push(...nextVessels);
  }
  return vessels;
};

/**
 * Given a set of parameters, initialise the DAAS System to begin a history request
 * The results will be returned via a websocket, with the provided clientId
 * @param startDatetime
 * @param endDatetime
 * @param mmsis
 * @param imos
 * @param vesselIds
 * @param clientId
 */
export const initialiseLocationDataViaWebsocket = async (
  startDatetime: Date,
  endDatetime: Date,
  clientId: string,
  mmsis?: string[],
  imos?: string[],
  vesselIds?: string[],
  downsampleRate: string = '1m', // 1m, 10m (only options currently)
  highTierSearchMode?: boolean
) =>
  // if high tier search maxExternalProviderWorkers: undefined, else limit maxExternalProviderWorkers to 1
  wrapRequest('post', 'geonius', '/ais-data', {
    body: {
      maxExternalProviderWorkers:
        highTierSearchMode !== undefined && highTierSearchMode ? undefined : 1,
      endpointSelection: 'websocket/location-messages',
      // becomes undefined with empty arrays
      mmsis:
        mmsis && mmsis.length > 0
          ? mmsis!.map((mmsi) => parseInt(mmsi, 10))
          : undefined,
      imos:
        imos && imos.length > 0
          ? imos!.map((imo) => parseInt(imo, 10))
          : undefined,
      vesselIds:
        vesselIds && vesselIds.length > 0
          ? vesselIds!.map((vesselId) => parseInt(vesselId, 10))
          : undefined,
      // modify datetimes for database query
      startDatetime: dateFns
        .startOfDay(startDatetime)
        .toISOString()
        .split('.')[0],
      endDatetime: dateFns.endOfDay(endDatetime).toISOString().split('.')[0],
      maxChunkSize: WEBSOCKET_CHUNK_SIZE, // websocket messages have a relatively small max size. 350 works, so 250 gives us some overhead
      clientId,
      downsampleRate,
    },
  });

export const getLocationDataDAAS = async (
  startDatetime: Date,
  endDatetime: Date,
  mmsis: string[],
  imos: string[],
  downsampleRate: string = '1m',
  limit: number = 10000,
  offset: number = 0
): Promise<MaritimeAisApiLocationData> =>
  wrapRequest('post', 'geonius', `/ais-data`, {
    body: {
      endpointSelection: 'location-messages',
      // becomes undefined with empty arrays
      mmsis:
        mmsis.length > 0 ? mmsis.map((mmsi) => parseInt(mmsi, 10)) : undefined,
      imos:
        imos!.length > 0 ? imos!.map((imo) => parseInt(imo, 10)) : undefined,
      mergeOnVessels: true,
      limit,
      offset,
      // modify datetimes for database query
      startDatetime: dateFns.startOfDay(startDatetime).toISOString(),
      endDatetime: dateFns.endOfDay(endDatetime).toISOString(),
      downsampleRate,
    },
  }).then(async (response) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { data_size } = response.metadata.pagination;
    if (data_size === limit) {
      const nextMessages = await getLocationDataDAAS(
        startDatetime,
        endDatetime,
        mmsis,
        imos,
        downsampleRate,
        limit,
        offset + limit
      );
      // for every vessel in next messages check if it already exists in messages, if not add it
      // if it does then append the messages to the existing vessel
      Object.entries(response.data).forEach(([vesselId]) => {
        const nextVesselData = nextMessages.data[vesselId];
        if (nextVesselData) {
          response.data[vesselId].messages.push(...nextVesselData.messages);
        }
      });
    }
    return response;
  });

/**
 * Use maritime_cold for RI Tenant, otherwise use new AIS API
 * If a websocketId is provided, then the websocket will be used to return the data
 * Otherwise, recursive http requests will be used
 * @param query
 * @returns
 */
export const getVesselHistory = async (
  query: VesselHistoryQuery,
  websocketId?: string
): Promise<VesselHistoryResponse | VesselData[]> => {
  // websocket id provided, use websocket mode
  if (websocketId) {
    // in websocket mode, we only get location messages, not vessel data.
    // so we need to get the vessel data separately
    // we also need to request it first, so it is ready when the websocket messages start coming through.
    const vesselData = await getVesselsDAAS({
      mmsis: query.mmsis,
      imos: query.imos,
      vesselIds: [],
    });
    initialiseLocationDataViaWebsocket(
      new Date(query['start-date']),
      new Date(query['end-date']),
      websocketId,
      query.mmsis,
      query.imos,
      [], // could also provide vesselIds here
      query['sample-rate'],
      query.highTierSearchMode
    );
    return vesselData;
  }

  const response = await getLocationDataDAAS(
    new Date(query['start-date']),
    new Date(query['end-date']),
    query.mmsis,
    query.imos,
    query['sample-rate'],
    10000,
    0
  );

  const data = (
    Object.values(response.data).filter(
      (value) => value.vessel
    ) as MaritimeAisApiLocationDefined[]
  ).map((vesselData) => geollectDaasFormatToGeoniusColdFormat(vesselData));

  return { data };
};

export const getAlertingVesselsWithLocations = async (): Promise<
  AlertingVessels[]
> => {
  const alertDataResponse = await getAlertingVesselsForCompany();
  const alertData = alertDataResponse.map((response) => {
    const resp: ParsedAlertDataResponse = {
      alertType: response.alert_type,
      alertTime: response.alert_time,
      expiryTime: response.expiry_time,
      name: response.vessel_name,
      type: response.vessel_type,
      additionalDetails: response.additional_details,
      alertConfigId: response.alert_config_id,
      mmsi: response.current_mmsi,
      imo: response.imo,
    };
    if (resp.alertType === 'ENTER_ROI' || resp.alertType === 'EXIT_ROI') {
      if (response.alert_modifiers.area_id) {
        resp.roiType = ROIType.MARITIME_AREA;
        resp.roiId = String(response.alert_modifiers.area_id);
        return resp;
      }
      if (response.route_id) {
        resp.roiType = ROIType.ROUTE;
        resp.roiId = response.route_id;
        return resp;
      }
      if (response.boundary_id) {
        resp.roiType = ROIType.BOUNDARY;
        resp.roiId = response.boundary_id;
        return resp;
      }
      if (response.region_id) {
        resp.roiType = ROIType.DRAWING;
        resp.roiId = response.region_id;
        return resp;
      }
    }
    return resp;
  });

  const alertingVessels: AlertingVessels[] = [];
  const vesselMmsis: number[] = alertDataResponse.map(({ current_mmsi }) =>
    parseInt(current_mmsi, 10)
  );

  // last known locations should not be queried if no mmsis found
  if (!vesselMmsis.length) {
    return alertingVessels;
  }

  const lastKnownLocations = await getLastKnownVesselLocations(vesselMmsis);
  alertData.forEach((alert) => {
    const location = Object.values(lastKnownLocations.data).find(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (lastKnownLocation) =>
        String(lastKnownLocation.vessel?.staticData.mmsi) === alert.mmsi
    );
    if (location) {
      const { vessel, messages } = location as MaritimeAisApiLocationDefined;
      // sometimes a location message has null position, so we need to find the first position that is not null
      const [long, lat] = messages.find((message) => message.position)?.position
        ?.coordinates || [null, null];
      if (!long || !lat) {
        // Vessel has no valid location messages
        return;
      }
      alertingVessels.push({
        vessel_id: vessel.vesselId,
        name: vessel.staticData.name,
        type: alert.type,
        course: messages[0].course,
        heading: messages[0].heading,
        latitude: lat,
        longitude: long,
        callsign: vessel.staticData.callsign,
        shiptype: vessel.staticData.shipType,
        timestamp: messages[0].timestamp,
        speed: messages[0].speed,
        imo: String(vessel.staticData.imo),
        mmsi: String(messages[0].mmsi),
        alertConfigId: alert.alertConfigId,
        alertType: alert.alertType,
        alertTime: alert.alertTime,
        expiryTime: alert.expiryTime,
        additionalDetails: alert.additionalDetails,
        roiType: alert.roiType,
        roiId: alert.roiId,
        source: VesselSource.AIS,
      });
    }
  });
  return alertingVessels;
};

interface VesselHistoryQuery {
  'start-date': string;
  'end-date': string;
  'sample-rate': string;
  mmsis: string[];
  imos: string[];
  highTierSearchMode?: boolean;
}

export interface HistoricVesselData {
  messages: HistoricVesselPoint[];
  vessel: Vessel;
}

export interface VesselHistoryResponse {
  data: HistoricVesselData[];
}

export interface VesselHistoryData extends VesselHistoryResponse {
  formValues: HistoryFormValues;
}

export interface VesselLocationData {
  id: string;
  timestamp: string;
  vessel_id: string;
  mmsi: number;
  msg_type: number;
  position: string;
  latitude: number;
  longitude: number;
  created_at: string;
  flag: string;
  speed: number;
  course: number;
  heading: number;
  status: number;
  collection_type: string;
  accuracy: number;
  rot: number;
  maneuver: number;
}

export const getVesselLocationsDAAS = async (
  mmsi: string,
  endDate: Date, // this prevents points further than the shown location being added to the map
  days: number = 7, // number of days to go back
  downsampleRate: string = '1m', // 1m, 1h or 1d
  limit: number = 10000,
  offset: number = 0
): Promise<MessageDataWithPosition[]> => {
  const startDate = dateFns.subDays(endDate, days);

  // remove trailing values as per API docs
  const startDatetime = startDate.toISOString().slice(0, 19);
  const endDatetime = endDate.toISOString().slice(0, 19);

  const messages: MessageDataWithPosition[] = [];

  await wrapRequest('post', 'geonius', `/ais-data`, {
    body: {
      endpointSelection: 'location-messages',
      mmsis: [parseInt(mmsi, 10)],
      limit,
      offset,
      downsampleRate,
      startDatetime,
      endDatetime,
    },
  }).then(async (response: MaritimeAisApiLocationOnlyResponse) => {
    if (response.data && Object.values(response.data).length > 0) {
      const dataArray = Object.values(response.data)[0];
      if (Array.isArray(dataArray)) {
        dataArray
          .filter((message) => message.position)
          .forEach((message) =>
            messages.push(message as MessageDataWithPosition)
          );
      }
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { data_size } = response.metadata.pagination;
    if (data_size === limit) {
      const nextMessages = await getVesselLocationsDAAS(
        mmsi,
        endDate,
        days,
        downsampleRate,
        limit,
        offset + limit
      );
      (
        nextMessages.filter(
          (message) => message.position
        ) as MessageDataWithPosition[]
      ).map((message) => messages.push(message));
    }
  });

  // the vessel location messages could be behind the vessels current position because of the downsampling of data,
  // so we need to get the vessel's last known location and add it to the messages
  const lastKnownLocationResponse = await getLastKnownVesselLocations([
    parseInt(mmsi, 10),
  ]);

  const vesselId = messages[0]?.vesselId;

  if (vesselId) {
    const vesselData = lastKnownLocationResponse?.data[vesselId];

    if (vesselData && vesselData.messages.length > 0) {
      const lastKnownLocationMessage = vesselData.messages[0];
      if (lastKnownLocationMessage?.position !== null) {
        messages.push(lastKnownLocationMessage as MessageDataWithPosition);
      }
    }
  }
  return messages;
};

export interface PostVesselsBody {
  data: {
    company_id: string;
    vessels: string; // data already in csv format
    replace: boolean;
  };
}

export const postVessels = async (
  body: PostVesselsBody
): Promise<
  { failed_vessels: VesselFormItem[] } | 'Successfully created vessel'
> =>
  wrapRequest('post', 'geonius', `/vessels`, {
    body,
    headers: {
      'Content-Type': 'text/csv',
    },
  });

export type NullableStringArray = Array<string | null>;
export type NullableNumberArray = Array<number | null>;

export interface PostVesselsV2Body {
  data: NullableNumberArray[];
  company_id: string | null;
  replace: boolean;
  explain: boolean;
  v2_fleets_request?: string;
}

export interface PostVesselsV2Response {
  total_vessels: number; // total vessels against this company after the operation
  vessels_added: number; // the amount of vessels added from the uploaded vessel list
  vessels_deleted: number; // vessels deleted if applicable
  imos_not_found: number[]; // imos provided that weren't found
  mmsis_not_found: number[]; // mmsis provided that weren't found
  imos_and_mmsis_not_found: [number, number][]; // paired imo and mmsis that werent found as [imo, mmsi]
}

export const postVesselsV2 = async (
  body: PostVesselsV2Body
): Promise<PostVesselsV2Response> =>
  wrapRequest('post', 'geonius', `/vessels/v2`, {
    body,
  });

export const deleteVessels = async (
  vessel_ids: string[]
): Promise<'Successfully deleted vessel(s)'> =>
  wrapRequest('del', 'geonius', `/vessels`, {
    body: {
      data: {
        vessel_ids,
      },
    },
  });

export const getMyFleetData = async (
  companyId: string | undefined,
  tenantId: string | undefined,
  aisAccess: boolean,
  riAccess: boolean
): Promise<MyFleetVessel[]> => {
  if (!companyId) {
    throw new Error('No company ID provided');
  }
  const fleetPromises = [];

  if (aisAccess) {
    fleetPromises.push(getFleetWithLocationsForCompany(companyId));
  }

  if (riAccess) {
    fleetPromises.push(getRiskIntelligenceFleetForCompany(companyId));
  }
  const responses = await Promise.all(fleetPromises);
  const fleet = responses.flat();

  return fleet;
};

export function loadAlertingVesselsWithLocations(idToken: Token | null) {
  getAlertingVesselsWithLocations()
    .then((response) => {
      // Set the Alerting Vessels based on the user's Tenant
      const visibleAlertTypes =
        AlertTypesByTenant[idToken?.tenantId as TenantId] || DEFAULT_ALERTS;
      const filteredAlertingVessels = response.filter((vessel) =>
        visibleAlertTypes.includes(vessel.alertType as EAlertTypes)
      );
      store.dispatch(setAlertingVessels(filteredAlertingVessels));
    })
    .catch(() => {
      store.dispatch(setError());
    });
}

const getDefaultFrequency = (
  default_history:
    | {
        frequency: DefaultHistoryFrequency;
      }
    | undefined
) => {
  let defaultFrequency = '1m';
  if (default_history?.frequency === DefaultHistoryFrequency.HOURLY) {
    defaultFrequency = '1h';
  } else if (default_history?.frequency === DefaultHistoryFrequency.DAILY) {
    defaultFrequency = '1d';
  }
  return defaultFrequency;
};

export const getRelatedVesselPoints = async (
  mmsi: string,
  eventTime: string | Date | number
): Promise<HistoricVesselPoint[]> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { default_history } = store.getState().userPreferences.userPreferences;
  const nowOr3DaysAfterEvent = dateFns.startOfDay(
    dateFns.min([
      dateFns.add(new Date(eventTime), {
        days: 3,
      }),
      new Date(),
    ])
  );

  const locations = await getVesselLocationsDAAS(
    mmsi,
    nowOr3DaysAfterEvent,
    default_history?.duration === DefaultHistoryDuration.MONTH ? 30 : 7,
    getDefaultFrequency(default_history)
  );
  const points = mapResponseToHistoricVesselPoints(locations);
  points.sort((a, b) => a.timestamp - b.timestamp);
  return points;
};

export const daasVesselToMyFleetVessel = (
  vessel: VesselData,
  latestVesselPoint?: HistoricVesselPoint
): Vessel => ({
  vessel_id: vessel.vesselId,
  name: vessel.staticData.name,
  longitude:
    latestVesselPoint?.longitude ??
    vessel.lastPositionUpdate.position.coordinates[0],
  latitude:
    latestVesselPoint?.latitude ??
    vessel.lastPositionUpdate.position.coordinates[1],
  course: latestVesselPoint?.course ?? 0,
  speed: latestVesselPoint?.speed ?? 0,
  heading: latestVesselPoint?.heading ?? 0,
  source: VesselSource.AIS,
  callsign: vessel.staticData.callsign,
  shiptype: vessel.staticData.shipType,
  imo: vessel.staticData.imo,
  mmsi: vessel.staticData.mmsi,
  timestamp: vessel.lastUpdatedAt,
});

interface VesselRfQuery {
  mmsis?: string;
  after?: string;
  before?: string;
  area?: string;
  event_type?: string;
}

interface RfTargetQuery {
  mmsis?: string;
  area?: string;
}
export interface VesselRfData {
  vessel: Vessel;
  rfData: FeatureCollection<Point>;
}

export const getVesselRfLocations = async (
  query: VesselRfQuery
): Promise<RfEvent[]> =>
  wrapRequest('get', 'geonius', '/rfgl/events', {
    queryStringParameters: query,
  });

export const getRfTargets = async (query: RfTargetQuery): Promise<RfTarget[]> =>
  wrapRequest('get', 'geonius', '/rfgl/targets', {
    queryStringParameters: query,
  });

/* eslint-disable import/no-named-as-default */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_MENU_STATE } from '../../main-menu/menu.slice';
import MapLayer from '../../map/map-layer-manager/map-layer.enum';
import { INITIAL_MAP_STATE } from '../../map/map.slice';
import { HistoryFormValues } from '../../maritime-menu-options/history-panel/history-form/history-form-validators';
import { INITIAL_HISTORY_PANEL_STATE } from '../../maritime-menu-options/history-panel/history-panel.slice';
import { defaultIncidentFilters } from '../../maritime-menu-options/incidents-panel/incident-panel.slice';

import {
  DEFAULT_FLEET_FILTERS,
  FleetFilters,
} from '../../maritime-menu-options/my-fleet-panel/myFleet-panel.slice';
import {
  DEFAULT_PORT_FILTERS,
  PortsFilters,
} from '../../maritime-menu-options/world-ports-panel/world-ports-panel.slice';

import { DEFAULT_FLEETS_FILTERS } from '../../maritime-menu-options/fleets-panel/fleets-panel.slice';
import { FleetsFilters } from '../../maritime-menu-options/fleets-panel/fleets.model';
import {
  FleetColouredBySelectedColour,
  FleetColouredByVesselType,
} from '../../maritime-menu-options/fleets-panel/manage-fleets/manage-fleets.slice';
import { IncidentFilters } from '../../maritime-menu-options/incidents-panel/incident.model';
import { UserPreferencesBaseMap } from '../../utils/user.enum';

export enum DefaultHistoryDuration {
  WEEK = '1 Week',
  MONTH = '1 Month',
}

export enum DefaultHistoryFrequency {
  DAILY = 'Daily',
  HOURLY = 'Hourly',
  EVERY_TEN_MINUTES = 'Every 10 Minutes',
}

export enum VesselPrefLabel {
  HIDE = 'Hide',
  SHOW = 'Show',
}

export enum VesselPrefSize {
  STANDARD = 'Standard',
  LARGE = 'Large',
}

export enum VesselPrefColour {
  NAVY = 'Navy',
  WHITE = 'White',
  ORANGE = 'Orange',
}

export type UserPreferences = {
  basemap: UserPreferencesBaseMap;
  osmChecked: boolean;
  datumRingsChecked: boolean;
  bathymetryChecked: boolean;
  showRiNewsBanner: boolean;
  showDatumRings: boolean;
  zoomToIncident: boolean;
  default_history?: {
    duration: DefaultHistoryDuration;
    frequency: DefaultHistoryFrequency;
  };
  vessel_preference: {
    vesselLabel: VesselPrefLabel;
    vesselSize: VesselPrefSize;
    vesselColour: VesselPrefColour;
  };
  mapExtent?: {
    center: [number, number];
    zoom: number;
  };
  menu?: {
    menuOpen: boolean;
    selectedOption: string;
    secondaryMenuOpen: boolean;
    selectedDossier: string;
    threatAssessmentOpen: boolean;
    securityRiskAssessmentOpen: boolean;
    vulnerabilityAssessmentOpen: boolean;
  };
  alertsPanel?: {
    layers: MapLayer[];
    disabled: boolean;
  };
  drawingsPanel?: {
    layers: MapLayer[];
    disabled: boolean;
  };
  routesPanel?: {
    layers: MapLayer[];
    disabled: boolean;
  };
  historyPanel?: {
    formValues: HistoryFormValues;
    layers: MapLayer[];
    disabled: boolean;
  };
  RIMaritimeAreasPanel?: {
    layers: MapLayer[];
    disabled: boolean;
  };
  myFleetPanel?: {
    filters: FleetFilters;
    layers: MapLayer[];
    disabled: boolean;
  };
  fleetsPanel?: {
    filters: FleetsFilters;
    layers: MapLayer[];
    disabled: boolean;
    visibleFleetIds: string[];
    isGlobalAisServiceOn: boolean;
  };
  fleetsVessels?: {
    vesselTypeDefaultColouring: boolean;
  };
  manageFleets?: {
    fleetColouredByVesselType: FleetColouredByVesselType[];
    fleetColouredBySelectedColour: FleetColouredBySelectedColour[];
  };
  globalAis?: {
    hideVesselsByType: string[];
    hideFlags: string[];
  };
  openSeaMapsPanel?: {
    layers: MapLayer[];
    disabled: boolean;
  };
  incidentsPanel?: {
    filters: IncidentFilters;
    layers: MapLayer[];
    disabled: boolean;
  };
  boundariesPanel?: {
    layers: string[];
    disabled: boolean;
  };
  worldPortsPanel?: {
    layers: MapLayer[];
    disabled: boolean;
    filters: PortsFilters;
  };
  displayMenu: boolean; // Add displayMenu here
  globalAisPanel?: {
    layers: MapLayer[];
    disabled: boolean;
  };
};

type UserPreferencesInitialState = {
  userPreferences: UserPreferences;
  userPreferencesLoading: boolean;
  mapSetupLoaded: boolean;
};

export const DEFAULT_USER_PREFERENCES: UserPreferences = {
  basemap: UserPreferencesBaseMap.DEFAULT,
  osmChecked: false,
  datumRingsChecked: false,
  bathymetryChecked: false,
  showRiNewsBanner: false,
  showDatumRings: true,
  zoomToIncident: true,
  default_history: {
    duration: DefaultHistoryDuration.WEEK,
    frequency: DefaultHistoryFrequency.DAILY,
  },
  vessel_preference: {
    vesselLabel: VesselPrefLabel.HIDE,
    vesselSize: VesselPrefSize.STANDARD,
    vesselColour: VesselPrefColour.WHITE,
  },
  mapExtent: {
    center: INITIAL_MAP_STATE.mapExtent.center,
    zoom: INITIAL_MAP_STATE.mapExtent.zoom,
  },
  menu: {
    ...INITIAL_MENU_STATE,
  },
  alertsPanel: {
    layers: [] as MapLayer[],
    disabled: false,
  },
  drawingsPanel: {
    layers: [] as MapLayer[],
    disabled: false,
  },
  historyPanel: {
    formValues: INITIAL_HISTORY_PANEL_STATE.formValues,
    layers: [] as MapLayer[],
    disabled: false,
  },
  myFleetPanel: {
    filters: DEFAULT_FLEET_FILTERS,
    layers: [] as MapLayer[],
    disabled: false,
  },
  fleetsPanel: {
    filters: DEFAULT_FLEETS_FILTERS,
    layers: [] as MapLayer[],
    disabled: false,
    visibleFleetIds: [],
    isGlobalAisServiceOn: false,
  },
  fleetsVessels: {
    vesselTypeDefaultColouring: false,
  },
  manageFleets: {
    fleetColouredByVesselType: [],
    fleetColouredBySelectedColour: [],
  },
  globalAis: {
    hideVesselsByType: [],
    hideFlags: [],
  },
  openSeaMapsPanel: {
    layers: [] as MapLayer[],
    disabled: false,
  },
  incidentsPanel: {
    filters: defaultIncidentFilters,
    layers: [] as MapLayer[],
    disabled: false,
  },
  boundariesPanel: {
    layers: [] as MapLayer[],
    disabled: false,
  },
  worldPortsPanel: {
    layers: [] as MapLayer[],
    disabled: false,
    filters: DEFAULT_PORT_FILTERS,
  },
  displayMenu: true, // Initialize displayMenu here
  globalAisPanel: {
    layers: [MapLayer.GLOBAL_AIS_VESSELS] as MapLayer[],
    disabled: false,
  },
};

export const INITIAL_USER_PREFERENCES_STATE: UserPreferencesInitialState = {
  userPreferences: {
    ...DEFAULT_USER_PREFERENCES,
  },
  userPreferencesLoading: false,
  mapSetupLoaded: false,
};

const UserPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState: INITIAL_USER_PREFERENCES_STATE,
  reducers: {
    setBaseMapSelected: (
      state,
      action: PayloadAction<UserPreferencesBaseMap>
    ) => {
      state.userPreferences.basemap = action.payload;
    },
    setUserPreferences: (state, action: PayloadAction<UserPreferences>) => {
      state.userPreferences = action.payload;
    },
    setUserPreferencesLoading: (state, action) => {
      state.userPreferencesLoading = action.payload;
    },
    setShowRiNewsBanner: (state, action) => {
      state.userPreferences.showRiNewsBanner = action.payload;
    },
    setShowDatumRings: (state, action) => {
      state.userPreferences.showDatumRings = action.payload;
    },
    setMapSetupLoaded: (state, action) => {
      state.mapSetupLoaded = action.payload;
    },
    setZoomToIncident: (state, action) => {
      state.userPreferences.zoomToIncident = action.payload;
    },
    toggleDisplayMenu: (state) => {
      // Add toggleDisplayMenu reducer
      state.userPreferences.displayMenu = !state.userPreferences.displayMenu;
    },
    setDisplayMenu: (state, action: PayloadAction<boolean>) => {
      // Add setDisplayMenu reducer
      state.userPreferences.displayMenu = action.payload;
    },
  },
});

export const {
  setBaseMapSelected,
  setUserPreferences,
  setUserPreferencesLoading,
  setShowRiNewsBanner,
  setShowDatumRings,
  setMapSetupLoaded,
  setZoomToIncident,
  toggleDisplayMenu,
  setDisplayMenu,
} = UserPreferencesSlice.actions;

export default UserPreferencesSlice.reducer;

import {
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { deleteFleet } from '../../../../api/fleets';
import { useAppDispatch } from '../../../../hooks';
import { removeFleetVisibility } from '../../fleets-panel.slice';
import {
  FleetsListVessel,
  FleetType,
  VesselListOwnershipType,
} from '../../fleets.model';
import {
  resetDeleteState,
  setDeleteError,
  setDeleteFleetData,
  setDeleteFleetDialogOpen,
  setDeleteLoading,
  setDeleteSuccess,
} from './delete-fleets-dialog.slice';

namespace DeleteFleetsDialogUtils {
  export const resetDeleteFleetDialogState = (
    dispatch: ReturnType<typeof useAppDispatch>
  ) => {
    dispatch(resetDeleteState());
  };

  export const openDeleteFleetDialog = (
    dispatch: ReturnType<typeof useAppDispatch>,
    fleetName: string,
    fleetId: string,
    vesselListOwner: VesselListOwnershipType,
    fleetType: FleetType,
    fleetVessels: FleetsListVessel[],
    fleetVesselIds: string[]
  ) => {
    dispatch(
      setDeleteFleetData({
        fleetName,
        fleetId,
        vesselListOwner,
        fleetType,
        fleetVessels,
        fleetVesselIds,
      })
    );
    dispatch(setDeleteFleetDialogOpen(true));
  };

  export const processDeleteFleet = async (
    dispatch: ReturnType<typeof useAppDispatch>,
    fleetId: string,
    vesselListOwner: VesselListOwnershipType,
    fleetType: FleetType,
    fleetVesselIds: string[] | [] = []
  ): Promise<string | null> => {
    try {
      dispatch(setDeleteLoading(true));
      const deleteResponse = await deleteFleet(
        fleetId,
        vesselListOwner,
        fleetType,
        fleetVesselIds
      );
      if (deleteResponse) {
        dispatch(setDeleteSuccess(true));
        dispatch(setDeleteLoading(false));
        if (deleteResponse.includes('Deleted vessel list')) {
          dispatch(removeFleetVisibility(fleetId));
        }
        return deleteResponse;
      }
    } catch (e) {
      dispatch(setDeleteError(true));
      dispatch(setDeleteLoading(false));
    }
    return null;
  };

  export const renderVesselItem = (vessel: FleetsListVessel) => {
    const vesselDetails = [];

    if (vessel.vessel_type) {
      vesselDetails.push(vessel.vessel_type.replaceAll('_', ' ').toLowerCase());
    }

    if (vessel.imo) {
      vesselDetails.push(`IMO: ${vessel.imo}`);
    }

    if (vessel.current_mmsi) {
      vesselDetails.push(`MMSI: ${vessel.current_mmsi}`);
    }

    const detailsText = vesselDetails.join(', ');

    return (
      <ListItem
        alignItems="flex-start"
        key={`${vessel.imo}-${vessel.current_mmsi}-${vessel.vessel_id}`}
        disableGutters
        disablePadding
        sx={{
          borderBottom: '1px solid',
          borderBottomColor: 'background.menuBorder',
          width: '99%',
        }}
      >
        <ListItemButton>
          <ListItemText
            primary={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Box>
                  {vessel.vessel_name ? `${vessel.vessel_name}` : 'No Name'}

                  <Typography
                    sx={{ color: 'text.secondary' }}
                    component="span"
                    variant="caption"
                    textTransform="capitalize"
                    alignItems="flex-start"
                  >
                    {detailsText && ` (${detailsText})`}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };
}

export default DeleteFleetsDialogUtils;
